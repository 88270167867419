import React from 'react';

const About = () => {
  return (
    <div>
      <h1>About this App</h1>
      <p className="my-1">This is a fullstack React App for keeping contacts</p>
      <p className="bg-dark p">
        <strong>Version</strong> 1.0.0
      </p>
    </div>
  );
};

export default About;
